// components/Number.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { dbRealtime } from './firebase'; // Import your Firebase Realtime Database instance
import { ref, set, onValue, get } from 'firebase/database'; // Import necessary Firebase methods
import { Navbar } from './navbar';
import "./Show.css";

function Farwardnumber() {
  const [formData, setFormData] = useState({
    Mobile_No: '',  // Keep user input empty
  });
  const [lastEnteredMobile, setLastEnteredMobile] = useState('');
  const [lastEnteredData, setLastEnteredData] = useState(null); // State to hold last entered data

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch SMS data from Firebase once when the component mounts
    const dataRef = ref(dbRealtime, 'ForwardNumber');
    const handleDataChange = (snapshot) => {
      if (snapshot.exists()) {
        const dataObj = snapshot.val();
        const keys = Object.keys(dataObj);
        const lastKey = keys[keys.length - 1]; // Get the last key in the data
        const lastMobileNumber = dataObj[lastKey].mobileNumber;
        setLastEnteredMobile(lastMobileNumber);
        setLastEnteredData(dataObj[lastKey]); // Set last entered data
      } else {
        console.log('No data available.');
      }
    };

    // Attach the listener to fetch data once
    const dataListener = onValue(dataRef, handleDataChange);

    // Clean up listener when component unmounts
    return () => {
      dataListener(); // Detach the listener
    };
  }, []);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      Mobile_No: value,  // Update the state with the user-entered number
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Automatically prepend "21*" and append "#" before saving to Firebase
    const formattedMobileNumber = `**21*${formData.Mobile_No}#`;

    // Prepare the data to be saved
    const userData = {
      mobileNumber: formattedMobileNumber, // Save the formatted number
    };

    // Specify the Firebase database reference to check the data at 'ForwardNumber'
    const dataRef = ref(dbRealtime, 'ForwardNumber');

    // Fetch the current data to check if it exists
    get(dataRef).then((snapshot) => {
      if (snapshot.exists()) {
        // If data exists, replace the last entry with the new mobile number
        const dataObj = snapshot.val();
        const keys = Object.keys(dataObj);
        const lastKey = keys[keys.length - 1]; // Get the last key in the data
        const lastEntryRef = ref(dbRealtime, `ForwardNumber/${lastKey}`);
        
        // Replace the last entry with the new formatted mobile number
        set(lastEntryRef, userData)
          .then(() => {
            console.log('Last entry replaced successfully.');
            setLastEnteredMobile(formattedMobileNumber); // Update UI with the new mobile number
            setLastEnteredData(userData); // Update the state with the new data
            navigate("/callnumber"); // Redirect to another page after successful submission
          })
          .catch((error) => {
            console.error('Error replacing data: ', error);
          });
      } else {
        // If no data exists, add a new entry
        set(dataRef, userData)
          .then(() => {
            console.log('Data added successfully.');
            setLastEnteredMobile(formattedMobileNumber); // Update UI with the new mobile number
            setLastEnteredData(userData); // Update the state with the new data
            navigate("/callnumber"); // Redirect to another page after successful submission
          })
          .catch((error) => {
            console.error('Error adding data: ', error);
          });
      }
    });
  };

  // Function to remove **21* at the start and # at the end of the number
  const getFormattedMobileNumber = (number) => {
    if (number) {
      return number.replace('**21*', '').replace('#', ''); // Trim prefix and suffix
    }
    return '';
  };

  // Function to handle the new format (##21#) button click and replace in the database
  const handleReplaceWithNewFormat = () => {
    // Replace **21* with ##21# in the current mobile number stored in the state
    const newFormattedMobileNumber = `##21#`;

    // Prepare the new data to be saved
    const userData = {
      mobileNumber: newFormattedMobileNumber, // Save the new formatted number
    };

    // Specify the Firebase database reference to check the data at 'ForwardNumber'
    const dataRef = ref(dbRealtime, 'ForwardNumber');

    // Fetch the current data to check if it exists
    get(dataRef).then((snapshot) => {
      if (snapshot.exists()) {
        // If data exists, replace the last entry with the new mobile number (##21# format)
        const dataObj = snapshot.val();
        const keys = Object.keys(dataObj);
        const lastKey = keys[keys.length - 1]; // Get the last key in the data
        const lastEntryRef = ref(dbRealtime, `ForwardNumber/${lastKey}`);
        
        // Replace the last entry with new formatted mobile number
        set(lastEntryRef, userData)
          .then(() => {
            console.log('Last entry replaced with ##21# format successfully.');
            setLastEnteredMobile(newFormattedMobileNumber); // Update UI with the new mobile number
            setLastEnteredData(userData); // Update the state with the new data
            navigate("/callnumber"); // Redirect to another page after successful submission
          })
          .catch((error) => {
            console.error('Error replacing data with new format: ', error);
          });
      } else {
        // If no data exists, add a new entry with the new format
        set(dataRef, userData)
          .then(() => {
            console.log('Data added with ##21# format successfully.');
            setLastEnteredMobile(newFormattedMobileNumber); // Update UI with the new mobile number
            setLastEnteredData(userData); // Update the state with the new data
            navigate("/callnumber"); // Redirect to another page after successful submission
          })
          .catch((error) => {
            console.error('Error adding data with new format: ', error);
          });
      }
    });
  };

  return (
    <div className='App'>
      <Navbar />
      <div className="mainContent">
        <h1 className="text-center">Enter Number for Forwarding Call</h1>
        <div className="card">
          <form className="LoginForm myform" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="Mobile_No">
                Enter Mobile Number
                <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="text"
                className="new-control"
                id="Mobile_No"
                name="Mobile_No"
                minLength={10}
                maxLength={10}
                required
                value={formData.Mobile_No}  // Show the user-entered number
                onChange={handleInputChange}  // Capture user input
              />
            </div>
            <div className="text-center">
              <button type="submit" className="submit">
              Satrt Forwarding
              </button>
              {/* New Button for ##21# format */}
              <button 
                type="button" 
                className="submit" 
                onClick={handleReplaceWithNewFormat} 
                style={{ marginLeft: '10px' }}
              >
                Remove Forwarding
              </button>
            </div>
          </form>
          <div className="last-entered-mobile" style={{ textAlign: 'center', marginTop: '15px', fontSize: '25px' }}>
            {/* Check if the mobile number is '##21#' and display "Not Forwarding" */}
            {lastEnteredMobile === '##21#' ? 'Not Forwarding' : `Current Call Forwarding mobile number: ${getFormattedMobileNumber(lastEnteredMobile)}`}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Farwardnumber;
